import React, { useEffect, useState } from "react"
import {
  BigImageInfo,
  DataItem,
  FullInfoText,
  FullNewsPage,
  H1,
  ImageWrapper,
  Img,
  MoreRow,
  NewsItems,
  NewsPageWrapper,
  NewsText,
  P,
  SeeMoreSingleNews,
  SharedWrapperCol,
  TextPart,
  Title,
  TitleRow,
} from "../../components/news/newsStyle"
import {
  FacebookIcon,
  FacebookShare,
  LinkdinIcon,
  LinkedinShare,
  ShareLabel,
} from "../../components/careercomponents/careerForm/formStyle"
import { Helmet } from "react-helmet";
import moment from "moment"
import triple from "../../api/triple"
import SEO from "../../components/seo"

const FullInfo = ({ pageContext, pageContext: { singleNews, locale, apiUrl, data } }) => {
  const [single, setSingle] = useState({})
  const [urlShared, setUrlShared] = useState()

  const getSharedUrl = lng => {
      if (lng === "en") {
        return `http://triple-c.am/en/news/${singleNews.id}/`
      } else {
        return `http://triple-c.am/arm/news/${singleNews.id}/`
      }
  }

  data && data.findIndex(item => {
    if (item.id === singleNews.id) {
      useEffect(() => {
        triple.get(`/api/news/${singleNews.id}`)
          .then(res => {
            setSingle(res.data.data)
          })
          .catch(err => console.log(err))
      }, [])
    }
  })

  const hookComponent = () => {
    setUrlShared(getSharedUrl(pageContext.locale))
  }

  useEffect(() => {
    singleNews.id && hookComponent()
  }, [singleNews && singleNews.id])

  useEffect(()=>{
    let test = singleNews && singleNews[`description_${locale}`];
    let description = document.querySelector('.description') && document.querySelector('.description');
    description.innerHTML = test;
  },[])

  const finalData = data && data.slice(0, 3)
  const settings = {
    infinite: true,
    swipe: true,
    slidesToShow: 2,
    arrows: false,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      }],
  }
  console.log("finalData", finalData)
  return (
    <>
      <SEO
        title={singleNews && singleNews[`title_${locale}`]}
        description={singleNews?.[`description_${locale}`]?.replace(/<[^>]+>/g, '')} // Strip HTML for meta description
        pageContext={pageContext}
        image={singleNews && apiUrl + singleNews.image}
      />
      <NewsPageWrapper>
        <BigImageInfo>
          <Img src={singleNews && apiUrl + singleNews.image} alt={singleNews && singleNews[`title_${locale}`]} />
        </BigImageInfo>
        <TitleRow>
          <H1> {singleNews && singleNews[`title_${locale}`]} </H1>
          <P>{singleNews && moment(singleNews.created_at).format("DD.MM.YYYY")}</P>
        </TitleRow>
        <FullInfoText className={'description'}>{singleNews && singleNews[`description_${locale}`]}</FullInfoText>
        <SharedWrapperCol>
          <ShareLabel>{pageContext.localeResources.translation.news.share}</ShareLabel>
          <FacebookShare
            url={urlShared}
            children={<FacebookIcon />}
          />

          <LinkedinShare
            url={urlShared}
            children={<LinkdinIcon />}
          />
        </SharedWrapperCol>
        <FullNewsPage {...settings}>
          {finalData && finalData.map((item, index) => (
            <NewsItems
              className="singleNews"
              to={`/${locale}/news/${item.id}/`}
              key={index}
              margin={item.margin}
            >
              <ImageWrapper>
                <img style={{ width: "100%" }} src={apiUrl + item.image} alt={item[`title_${locale}`]} />
              </ImageWrapper>
              <TextPart>
                <Title>{item[`title_${locale}`]}</Title>
                <NewsText>{item[`description_${locale}`]}</NewsText>
                <MoreRow>
                  <DataItem>{moment(item.created_at).format("DD.MM.YYYY")}</DataItem>
                  <SeeMoreSingleNews
                    className="see_more_btn"
                  > {pageContext.localeResources.translation.news.see_more}
                  </SeeMoreSingleNews>
                </MoreRow>
              </TextPart>
            </NewsItems>
          ))}
        </FullNewsPage>
      </NewsPageWrapper>
    </>
  )
}
export default FullInfo